import React, { useState, useImperativeHandle, forwardRef } from 'react';
import HelpCard from '../HelpCard';
import { useSelector, useDispatch } from 'react-redux';
import { filterVoucherTypeData, numberSeriesList, serialNumberList } from '../../../redux/actions/action-creator';

let filter_voucher = [];
const VoucherHelp = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { userData } = useSelector(state => state.auth);
    const { voucher_type_data = null } = useSelector(state => state.common);
    // display name voucher type in the userInterface------------------------------------------------------------------------
    const displayNameOfVoucherType = (name, voucher_type_data,) => {
        props.setcurrentIndex(0)
        filter_voucher = voucher_type_data?.filter((item) => {
            return (item?.select_type_of_voucher?.replace(/\s/g, '') === name?.replace(/\s/g, ''))
        })
        if (filter_voucher?.length > 1) {
            dispatch(filterVoucherTypeData(filter_voucher))
            props.setshow_voucher_type(true)
        } else {
            if (filter_voucher[0]?.vaucher_number_series_data?.length > 1) {
                dispatch(filterVoucherTypeData(filter_voucher))
                numberlistfun(filter_voucher[0]?.voucher_type_name)
                props.setshow_voucher_type(true)
            } else {
                const serial = new FormData();
                serial.append('voucher_type_name', filter_voucher[0]?.voucher_type_name)
                serial.append('voucher_number_series_id', filter_voucher[0]?.vaucher_number_series_data[0]?.id)
                serial.append('company_id', userData?.id)
                dispatch(serialNumberList(serial)).then((response) => {
                    if (response.status) {
                        props.setData({ ...props.data, entry_name: name, entry_name_id: filter_voucher[0]?.id, serial_no: response.new_serial_no, voucher_number_series_id: filter_voucher[0]?.vaucher_number_series_data[0]?.id })
                    }
                }).catch((e) => {
                    console.log(e)
                })
            }
            localStorage.setItem('voucher_type_name', name);
        }
    }
    const numberlistfun = (name) => {
        const formdata = new FormData();
        formdata.append('voucher_type_name', name)
        formdata.append('company_id', userData?.id)
        dispatch(numberSeriesList(formdata)).then((response) => { console.log("this i s= response :------", response) })
    }
    // Expose the function to the parent
    useImperativeHandle(ref, () => ({
        displayNameOfVoucherType
    }));

    const fields = [
        { id: "1", name: "Date", shortcutKey: "F2", state: props.setshow_period },
        { id: "2", name: "Contra", shortcutKey: "F4", state: () => displayNameOfVoucherType('Contra', voucher_type_data) },
        { id: "3", name: "Payment", shortcutKey: "F5", state: () => displayNameOfVoucherType('Payment', voucher_type_data) },
        { id: "4", name: "Receipt", shortcutKey: "F6", state: () => displayNameOfVoucherType('Receipt', voucher_type_data) },
        { id: "5", name: "Journal", shortcutKey: "F7", state: () => displayNameOfVoucherType('Journal', voucher_type_data) },
        { id: "6", name: "Sales", shortcutKey: "F8", state: () => displayNameOfVoucherType('Sales', voucher_type_data) },
        { id: "7", name: "Purchase", shortcutKey: "F9", state: () => displayNameOfVoucherType('Purchase', voucher_type_data) },
        { id: "8", name: "Other Voucher", shortcutKey: "F10", state: props.setshow_other_voucher },
        { id: "9", name: "Change Mode", shortcutKey: "H", state: props.setchange_mode },
        { id: "10", name: "Post Dated", shortcutKey: "L", state: props.setShowChange },
    ]


    return (
        <>
            {
                fields.map((item, index) => {
                    return (
                        <HelpCard key={item.id} fields={item} />
                    )
                })
            }
            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <div className='help_button_card mb-1 ms-1' >
                    <button className={`button_padding`} style={{ background: 'white', paddingTop: 3, paddingBottom: 3, marginBottom: 2 }}>
                        <p className='card-header'>
                            <span>F12</span>:
                            Configure
                        </p>
                    </button>
                </div>
            </div>

        </>
    )
})

export default VoucherHelp;
