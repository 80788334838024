import React, { useState, useRef,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';



function DoubleMode(props) {
    const { data } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [double_mode_input, setdouble_mode_input] = useState([{ id: '1', credit_debit: 'Cr', particular_ledger_name: '', particular_ledger_balance: '', particular_ledger_id: '', debit_amount: '', credit_amount: '' }])
    const [filteredData, setFilteredData] = useState([])
    const [selectedItem, setSelectedItem] = useState(0);
    const [total_trigger_credit, settotal_trigger_credit] = useState(false)
    const [total_trigger_debit, settotal_trigger_debit] = useState(false)
    const [store_index, setstore_index] = useState(0);
    const [currentIndex, setcurrentIndex] = useState(0)
    const [contra_account_ledger_list, setcontra_account_ledger_list] = useState([]);
    const [show_particular_double_list, setshow_particular_double_list] = useState(false);
    const [currentBalance, setCurrentBalance] = useState();
    const credit_debit_input_ref = useRef([])
    const particular_ledger_double_name_input_ref = useRef([])
    const debit_input_ref = useRef([])
    const credit_input_ref = useRef([])
    const dropdownRef = useRef([])
    const [total_amount, settotal_amount] = useState(0);
    const [total_amount_item, settotal_amount_item] = useState(0);
    const [total_amount_credit, settotal_amount_credit] = useState(0);
    const [total_amount_debit, settotal_amount_debit] = useState(0);
    const [total_trigger, settotal_trigger] = useState(false)

    // getting the total of amount ---------------------------------------------------------------------------
    useEffect(() => {
        if (total_trigger_credit) {
            let new_total_amount = double_mode_input.reduce((acc, item) => {
                let creditamount = parseFloat(item?.credit_amount?.replace(/\,/g, '')) || 0;
                return acc + creditamount;
            }, 0)
            settotal_amount_credit(new_total_amount);
            settotal_trigger(false)
        }
        if (total_trigger_debit) {
            let new_total_amount = double_mode_input.reduce((acc, item) => {
                let debitamount = parseFloat(item?.debit_amount?.replace(/\,/g, '')) || 0;
                return acc + debitamount;
            }, 0)
            settotal_amount_debit(new_total_amount);
            settotal_trigger(false)
        }
    }, [handleDoubleModeInputChange])

    // Triger function whenever the change input value to infenite input rendering 
    const handleDoubleModeInputChange = (index, key, value) => {
        setdouble_mode_input((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
        });
        if (key === 'particular_ledger_name') {
            setstore_index(index)
            const filteredSuggestions = Object.keys(contra_account_ledger_list).filter(key =>
                contra_account_ledger_list[key]?.ledger_name?.toLowerCase()?.includes(double_mode_input[index]?.particular_ledger_name?.toLowerCase()));
            setFilteredData(filteredSuggestions);
            setshow_particular_double_list(true)
        }
        if (key === 'credit_debit') {
            setdouble_mode_input((prevFormData) => {
                const updatedFormData = [...prevFormData];
                updatedFormData[index] = {
                    ...updatedFormData[index],
                    credit_debit: value?.toLowerCase()?.replace(/(^|\s)\S/g, (firstLetter) => firstLetter?.toUpperCase()),
                };
                return updatedFormData;
            });
        }
    }
    // Triger function whenever user press enter key on input field 
    const handleDoubleKeyUp = (index, key, value, e) => {
        if (key === 'credit_debit') {
            if (e.key === 'Enter' && double_mode_input[index]?.credit_debit !== '') {
                particular_ledger_double_name_input_ref.current[index].focus();
            }
        }
        if (key === 'debit_amount') {
            if (e.key === 'Enter' && double_mode_input[index].particular_ledger_name !== '' && double_mode_input[index].credit_debit !== '') {
                const intValue = parseInt(value, 10);
                if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
                    settotal_trigger_debit(true)
                    const newParticularLedgerBalance = parseFloat(currentBalance) + parseFloat(value);
                    setCurrentBalance(newParticularLedgerBalance);
                    setdouble_mode_input((prevFormData) => {
                        const updatedFormData = prevFormData.map((item, currentIndex) => {
                            if (item.particular_ledger_id === prevFormData[index].particular_ledger_id) {
                                return {
                                    ...item,
                                    particular_ledger_balance: newParticularLedgerBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&'),
                                };
                            }
                            return item;
                        });
                        return updatedFormData;
                    });
                    const isLastInputField = index === double_mode_input.length - 1;
                    if (isLastInputField) {
                        setdouble_mode_input((prevFormData) => [
                            ...prevFormData,
                            { id: `${prevFormData.length + 1}`, credit_debit: '', particular_ledger_name: '', particular_ledger_balance: '', particular_ledger_id: '', debit_amount: '', credit_amount: '' },
                        ]);
                        const newIndex = index + 1;
                        setTimeout(() => {
                            credit_debit_input_ref.current[newIndex].focus();
                            setdouble_mode_input((prevFormData) => {
                                const updatedFormData = [...prevFormData];
                                updatedFormData[index]['debit_amount'] = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                return updatedFormData;
                            });
                        }, 50);
                        console.log("this is double ---", double_mode_input)
                        settotal_trigger_debit(true)
                    }
                }
            }
        }
        if (key === 'credit_amount') {
            if (e.key === 'Enter' && double_mode_input[index].particular_ledger_name !== '' && double_mode_input[index].credit_debit !== '') {
                const intValue = parseInt(value, 10);
                if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
                    settotal_trigger_credit(true)
                    const newParticularLedgerBalance = parseFloat(currentBalance) - parseFloat(value);
                    setCurrentBalance(newParticularLedgerBalance);
                    setdouble_mode_input((prevFormData) => {
                        const updatedFormData = prevFormData.map((item, currentIndex) => {
                            if (item.particular_ledger_id === prevFormData[index].particular_ledger_id) {
                                return {
                                    ...item,
                                    particular_ledger_balance: newParticularLedgerBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&'),
                                };
                            }
                            return item; // Keep other elements unchanged
                        });
                        return updatedFormData;
                    });
                    const isLastInputField = index === double_mode_input.length - 1;
                    if (isLastInputField) {
                        setdouble_mode_input((prevFormData) => [
                            ...prevFormData,
                            { id: `${prevFormData.length + 1}`, credit_debit: '', particular_ledger_name: '', particular_ledger_balance: '', particular_ledger_id: '', debit_amount: '', credit_amount: '' },
                        ]);
                        const newIndex = index + 1;
                        setTimeout(() => {
                            credit_debit_input_ref.current[newIndex].focus();
                            setdouble_mode_input((prevFormData) => {
                                const updatedFormData = [...prevFormData];
                                updatedFormData[index]['credit_amount'] = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                return updatedFormData;
                            });
                        }, 50);
                        settotal_trigger_credit(true)
                    }
                }
            }
        }
    }
    return (
        <>
            <div className='row border border-dark mt-0'>
                <div className='col-1' style={{ width: '5%' }} ></div>
                <div className='col-7 font-weight-bold' style={{ textAlign: 'left' }}>Particular</div>
                <div className='col-2 font-weight-bold ' style={{ textAlign: 'right' }}>Debit</div>
                <div className='col-2 font-weight-bold ' style={{ textAlign: 'right' }}>Credit</div>
            </div>
            <div className='row' style={data.entry_name === 'Sales' ? { height: '37vh', overflowY: 'scroll' } : { height: '70vh', overflowY: 'scroll', display: 'flex', flexDirection: 'column' }}>
                {double_mode_input.map((item, index) => (
                    <div key={index} className={`col-12 m-0 mt-1`} style={{ height: double_mode_input[index]?.particular_ledger_name ? 45 : 25, paddingLeft: 12, paddingRight: 12 }}>
                        <div className='row'>
                            <div className='col-1' style={{ width: '5%' }}>
                                <input
                                    type='text'
                                    style={{ textAlign: 'right', paddingRight: 5 }}
                                    name={`credit_debit${index}`}
                                    ref={(input) => (credit_debit_input_ref.current[index] = input)}
                                    className='voucher_entry_double_mode_input'
                                    onKeyUp={(e) => handleDoubleKeyUp(index, 'credit_debit', e.target.value, e)}
                                    onChange={(e) => handleDoubleModeInputChange(index, 'credit_debit', e.target.value)}
                                    value={double_mode_input[index]?.credit_debit || ''}
                                />
                            </div>
                            <div className='col-3'>
                                <input
                                    type='text'
                                    autoFocus={double_mode_input.length <= 1 ? true : false}
                                    name={`particular_ledger_name${index}`}
                                    ref={(input) => (particular_ledger_double_name_input_ref.current[index] = input)}
                                    className='voucher_entry_double_mode_input'
                                    onKeyUp={(e) => handleDoubleKeyUp(index, 'particular_ledger_name', e.target.value, e)}
                                    onChange={(e) => handleDoubleModeInputChange(index, 'particular_ledger_name', e.target.value)}
                                    value={double_mode_input[index]?.particular_ledger_name || ''}
                                />
                                <div id="myDropdown-list" className={`dropdown-content-list ${show_particular_double_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                        List Of ledger account
                                    </div>
                                    <div className='col ' style={{ textAlign: 'right' }}>
                                        <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                        <div className='border'></div>
                                    </div>
                                    <div id='data'>
                                        {filteredData?.length > 0 ? (
                                            filteredData?.map((key, number) => {
                                                const name = contra_account_ledger_list[key]?.ledger_name;
                                                const id = contra_account_ledger_list[key]?.id;
                                                const inner_index = name?.toLowerCase()?.indexOf(double_mode_input[index]?.particular_ledger_name?.toLowerCase());
                                                return (
                                                    <Link
                                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                        id='list'
                                                        key={number}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setSelectedItem(number)
                                                            if (number === selectedItem) {
                                                                // setValueLoop((prevValueLoop) => {
                                                                //   const updatedValueLoop = [...prevValueLoop];
                                                                //   updatedValueLoop[index]['first'] = name;
                                                                //   return updatedValueLoop;
                                                                // });

                                                                // setshow_particular_list(false)
                                                                // const nextInput = particular_ledger_pay_amount_input_ref.current[index];
                                                                // if (nextInput) {
                                                                //   nextInput.focus();
                                                                // }
                                                            }
                                                        }}
                                                    >
                                                        {index !== -1 ? (
                                                            < >
                                                                {name?.substring(0, inner_index)}
                                                                <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + double_mode_input[index].particular_ledger_name.length)}</span>
                                                                {name?.substring(inner_index + double_mode_input[index].particular_ledger_name.length)}
                                                            </>
                                                        ) : (
                                                            name
                                                        )}
                                                    </Link>
                                                );
                                            })
                                        ) : (
                                            <p style={{ color: 'red' }}>No matching items found</p>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className='col-2 offset-4'>
                                <input
                                    type='text'
                                    style={{ textAlign: 'right', paddingRight: 5 }}
                                    name={`debit_amount${index}`}
                                    ref={(input) => (debit_input_ref.current[index] = input)}
                                    className='voucher_entry_double_mode_input'
                                    onKeyUp={(e) => handleDoubleKeyUp(index, 'debit_amount', e.target.value, e)}
                                    onChange={(e) => handleDoubleModeInputChange(index, 'debit_amount', e.target.value)}
                                    value={double_mode_input[index]?.debit_amount || ''}
                                />
                            </div>
                            <div className='col-2'>
                                <input
                                    type='text'
                                    style={{ textAlign: 'right', paddingRight: 5 }}
                                    name={`credit_amount${index}`}
                                    ref={(input) => (credit_input_ref.current[index] = input)}
                                    className='voucher_entry_double_mode_input'
                                    onKeyUp={(e) => handleDoubleKeyUp(index, 'credit_amount', e.target.value, e)}
                                    onChange={(e) => handleDoubleModeInputChange(index, 'credit_amount', e.target.value)}
                                    value={double_mode_input[index]?.credit_amount || ''}
                                />
                            </div>
                        </div>
                        {double_mode_input[index]?.particular_ledger_name !== '' ?
                            <div className='row mt-0 pt-0 pb-0 mb-0'>
                                <div className='col-10 mt-0 pt-0 pb-0 mb-0 offset-2'>
                                    <p className={'m-0 p-0 text-muted'}><i>Cur Bal: <span className={double_mode_input[index]?.particular_ledger_balance < 0 ? 'color_red' : 'm-0 p-0 text-muted'}>{double_mode_input[index]?.particular_ledger_balance}</span> <span className={double_mode_input[index]?.particular_ledger_balance < 0 ? 'color_red' : 'm-0 p-0 text-muted'}>{double_mode_input[index]?.particular_ledger_balance == 0 ? '' : double_mode_input[index]?.particular_ledger_balance < 0 ? 'Cr' : 'Dr'}</span></i></p>
                                </div>
                            </div>
                            : null}
                    </div>
                ))}
            </div>
        </>
    )
}

export default DoubleMode
