import React, { useState, useEffect } from 'react';
import './login.css';
import { EyeSlash, Eye } from 'react-bootstrap-icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormData from 'form-data';
import { signIn, voucherTypeList, godownList } from '../../redux/actions/action-creator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
const initialState = {
    customercode: '',
    userName: '',
    password: ''
}
function Login({ setProgress }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userData = null } = useSelector(state => state.auth);

    const [state, setState] = useState(initialState)
    const [visible, setVisible] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    }
    const handleClick = () => {
        setVisible(!visible);
    };
    const {
        customercode,
        userName,
        password
    } = state;

    const handleSubmit = (e1) => {
        e1.preventDefault();
        if (state.customercode !== "") {
            if (state.userName !== "") {
                if (state.password !== "") {
                    setLoading(true);
                    setProgress(10)
                    let data = new FormData();
                    data.append('user_name', userName);
                    data.append('password', password);
                    data.append('customer_code', customercode);
                    dispatch(signIn(data)).then((response) => {
                        if (response.status) {
                            setState({ customercode: '', userName: '', password: '' })
                            // toast.success(response?.message || 'Login Success!', {
                            //     position: toast.POSITION.TOP_CENTER
                            // });
                            setProgress(30)
                            localStorage.setItem('authToken', userData?.id);
                            setTimeout(() => {
                                setLoading(false);
                                setProgress(100)
                                // Set loading to false after a successful login
                                // navigate('/home');
                            }, 1000);
                        } else {
                            toast.warn(response?.message || 'Login Fail !', {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setLoading(false);
                        }
                    }).catch(error => {
                        console.log(error)
                        toast.error(error?.message || 'Login Failed!', {
                            position: toast.POSITION.TOP_CENTER
                        });
                        setLoading(false);
                    })
                } else {
                    alert('please enter password')
                }
            } else {
                alert('please inter username')
            }
        } else {
            alert('please inter customer code')
        }
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); 
        }, 10);

        return () => clearTimeout(timer); 
    }, []);
    
    const registerPage = (e) => {
        navigate('/signUp');
    }

    return (
        <div className='container-fluid' id='login-container'>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            {
                loading ? (
                    <div className='container'>
                        <div className='row justify-content-center align-items-center' id='row'>
                            <div className="spinner-border text-success" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='row justify-content-center align-items-center' id='row'>
                        <div className='col-lg-6'>
                            <h1 className='text-primary text-left' style={{ textAlign: 'left' }} id='man-heading'>
                                <b> Tally Prime </b>
                            </h1>
                            <h4 id='subheading ' style={{ textAlign: 'left' }}>
                                <b> Tally Prime help you to connect and share your company details with all the Employees</b>
                            </h4>
                        </div>
                        <div className='col-lg-4 p-5'>
                            <div className='row shadow rounded' id='card'>
                                <div className='col mt-4'>
                                    <form method='post' autoComplete='off' onSubmit={handleSubmit}>
                                        <div className="input-group input-group-lg mb-3">
                                            <input type="text" className="form-control login_input" autoComplete="off" placeholder="Customer Code" name='customercode' value={customercode || ""} onChange={handleChange} onKeyUp={handleChange} />
                                        </div>
                                        <div className="input-group input-group-lg mb-3">
                                            <input type="text" className="form-control login_input text-truncate" autoComplete="off" placeholder="Username" name='userName' value={userName || ""} onChange={handleChange} onKeyUp={handleChange} />
                                        </div>
                                        <div className="input-group input-group-lg mb-3">
                                            <input type={visible ? 'text' : 'password'} className="form-control login_input" autoComplete="off" id='password' placeholder="Password" name='password' value={password || ""} onChange={handleChange} onKeyUp={handleChange} />
                                            <span className='input_icon-wrapper'>
                                                {
                                                    visible ? <Eye className='input_icon' onClick={() => { setVisible(false) }} /> : <EyeSlash className='input_icon' onClick={handleClick} />
                                                }
                                            </span>
                                        </div>
                                        <ul className="nav">
                                            <li className="nav-item text-center">
                                                <Link className="nav-link active forgot_text" >Forgot password?</Link>
                                            </li>
                                        </ul>
                                        <div className='input-group input-group-lg mb-3'>
                                            <button type="submit" style={{ width: '100%', fontSize: 15 }} className="btn btn-primary"><strong>Sign In</strong></button>
                                        </div>
                                        <ul className="nav">
                                            <li className="nav-item text-center">
                                                <Link to='/branch_login' className="nav-link active forgot_text">Login for Branch?</Link>
                                            </li>
                                        </ul>


                                        <div className='border'></div>
                                        <div className='input-group input-group-lg mb-3 mt-3'>
                                            <button type="button" style={{ width: '100%', fontSize: 15 }} className="btn btn-success" onClick={() => { return (registerPage()) }}><strong> Register Now</strong></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>)}
        </div>

    )
}

export default Login;
