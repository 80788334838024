// const getValueFromDropdownOfItem = (setItem_entry,selectedLink, currentIndex) => {
//     console.log("this is selected item :---",selectedLink)
//     if (selectedLink?.name === 'End Of List') {
//         setItem_entry((prevValueLoop) => {
//             const updatedValueLoop = [...prevValueLoop];
//             updatedValueLoop[currentIndex]['item_name'] = ''
//             updatedValueLoop[currentIndex]['item_id'] = ''
//             updatedValueLoop[currentIndex]['check_open'] = true
//             return updatedValueLoop;
//         });
//     } else {
//         if (selectedLink?.per !== '' && selectedLink?.total_no_box_unit !== '') {
//             const quotient = Math.floor(parseInt(selectedLink?.where_unit) / parseInt(selectedLink?.where_alternate_unit));
//             const remainder = parseFloat(selectedLink?.where_unit) % parseFloat(selectedLink?.where_alternate_unit);
//             setItem_entry((prevValueLoop) => {
//                 const updatedValueLoop = [...prevValueLoop];
//                 updatedValueLoop[currentIndex]['check_open'] = false
//                 updatedValueLoop[currentIndex]['item_name'] = selectedLink.name;
//                 updatedValueLoop[currentIndex]['item_id'] = selectedLink.id;
//                 // updatedValueLoop[currentIndex]['godown_location_id'] = data.location==='Any'? data.location_id:selectedLink.id;
//                 updatedValueLoop[currentIndex]['item_qty'] = updatedValueLoop[currentIndex]?.item_qty !== '' ? updatedValueLoop[currentIndex]?.item_qty : `1 ${selectedLink?.default_unit_id !== '' ? selectedLink?.default_unit_name : selectedLink?.unit_name}`
//                 // updatedValueLoop[currentIndex]['item_rate'] = updatedValueLoop[currentIndex]?.item_rate !== '' ? currencyFormate(parseFloat(updatedValueLoop[currentIndex]?.item_rate)) : currencyFormate(parseFloat(selectedLink?.default_unit_rate))
//                 updatedValueLoop[currentIndex]['per_unit'] = updatedValueLoop[currentIndex]?.per_unit !== '' ? updatedValueLoop[currentIndex]?.per_unit : selectedLink.unit_name
//                 // updatedValueLoop[currentIndex]['item_amt'] = updatedValueLoop[currentIndex]?.item_amt !== '' ? currencyFormate(parseFloat(updatedValueLoop[currentIndex]?.item_amt)) : currencyFormate(parseFloat(selectedLink?.default_unit_rate))
//                 updatedValueLoop[currentIndex]['item_rate'] = ''
//                 updatedValueLoop[currentIndex]['item_amt'] = ''
//                 updatedValueLoop[currentIndex]['item_alternate_unit'] = selectedLink?.alt_unit_name
//                 updatedValueLoop[currentIndex]['main_unit_name'] = selectedLink?.unit_name
//                 updatedValueLoop[currentIndex]['where_unit_num'] = selectedLink?.where_unit
//                 updatedValueLoop[currentIndex]['where_alternate_unit_num'] = selectedLink?.where_alternate_unit
//                 updatedValueLoop[currentIndex]['is_unit_available'] = selectedLink?.unit_name
//                 updatedValueLoop[currentIndex]['combination_of_units'] = `${quotient} ${selectedLink?.alt_unit_name}+${remainder} ${selectedLink?.unit_name}`
//                 updatedValueLoop[currentIndex]['total_nos_box'] = `${quotient} ${selectedLink?.alt_unit_name}+${remainder} ${selectedLink?.unit_name}`
//                 updatedValueLoop[currentIndex]['total_no_box_unit'] = selectedLink?.alt_unit_name
//                 return updatedValueLoop;
//             });
//         } else if (selectedLink?.unit_name !== 'Not Applicable' && selectedLink?.unit_name !== '' && selectedLink?.alt_unit_name === 'Not Applicable') {
//             setItem_entry((prevValueLoop) => {
//                 const updatedValueLoop = [...prevValueLoop];
//                 updatedValueLoop[currentIndex]['check_open'] = false
//                 updatedValueLoop[currentIndex]['item_name'] = selectedLink.name;
//                 updatedValueLoop[currentIndex]['item_id'] = selectedLink.id;
//                 updatedValueLoop[currentIndex]['item_qty'] = `1 ${selectedLink?.default_unit_id !== '' ? selectedLink?.default_unit_name : selectedLink?.unit_name}`
//                 // updatedValueLoop[currentIndex]['item_rate'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
//                 updatedValueLoop[currentIndex]['per_unit'] = selectedLink.unit_name
//                 // updatedValueLoop[currentIndex]['item_amt'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
//                 updatedValueLoop[currentIndex]['item_rate'] = ''
//                 updatedValueLoop[currentIndex]['item_amt'] = ''
//                 updatedValueLoop[currentIndex]['is_unit_available'] = selectedLink?.unit_name
//                 updatedValueLoop[currentIndex]['combination_of_units'] = ''
//                 updatedValueLoop[currentIndex]['total_nos_box'] = ''
//                 updatedValueLoop[currentIndex]['total_no_box_unit'] = ''
//                 return updatedValueLoop;
//             });
//         } else {
//             setItem_entry((prevValueLoop) => {
//                 const updatedValueLoop = [...prevValueLoop];
//                 updatedValueLoop[currentIndex]['check_open'] = false
//                 updatedValueLoop[currentIndex]['item_name'] = selectedLink.name;
//                 updatedValueLoop[currentIndex]['item_id'] = selectedLink.id;
//                 updatedValueLoop[currentIndex]['item_qty'] = `1 ${selectedLink?.default_unit_id !== '' ? selectedLink?.default_unit_name : selectedLink?.unit_name}`
//                 // updatedValueLoop[currentIndex]['item_rate'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
//                 updatedValueLoop[currentIndex]['per_unit'] = selectedLink.unit_name
//                 // updatedValueLoop[currentIndex]['item_amt'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
//                 updatedValueLoop[currentIndex]['item_rate'] = ''
//                 updatedValueLoop[currentIndex]['item_amt'] = ''
//                 updatedValueLoop[currentIndex]['is_unit_available'] = selectedLink?.unit_name
//                 updatedValueLoop[currentIndex]['combination_of_units'] = ''
//                 updatedValueLoop[currentIndex]['total_nos_box'] = ''
//                 updatedValueLoop[currentIndex]['total_no_box_unit'] = ''

//                 return updatedValueLoop;
//             });
//         }
//     }
// }
// export {getValueFromDropdownOfItem}

const getValueFromDropdownOfItem = (setItem_entry, selectedLink, currentIndex) => {
    console.log("this is selected item :----", selectedLink)

    // Helper function to update state
    const updateItemEntry = (updates) => {
        setItem_entry((prevValueLoop) => {
            const updatedValueLoop = [...prevValueLoop];
            updatedValueLoop[currentIndex] = { ...updatedValueLoop[currentIndex], ...updates };
            return updatedValueLoop;
        });
    };

    // Common item properties to be set regardless of condition
    const commonUpdates = {
        check_open: false,
        item_name: selectedLink.name,
        item_id: selectedLink.id,
        is_unit_available: selectedLink?.unit_name,
    };

    // If "End Of List" is selected, reset item to default state
    if (selectedLink?.name === 'End Of List') {
        updateItemEntry({
            item_name: '',
            item_id: '',
            check_open: true
        });
        return;
    }

    // If "per" and "total_no_box_unit" are provided, calculate alternate unit and quantity
    if (selectedLink?.unit_name !== '' && selectedLink?.unit_name !== 'Not Applicable') {
        const quotient = Math.floor(parseInt(selectedLink?.where_unit) / parseInt(selectedLink?.where_alternate_unit));
        const remainder = parseFloat(selectedLink?.where_unit) % parseFloat(selectedLink?.where_alternate_unit);

        updateItemEntry({
            ...commonUpdates,
            item_qty: `1 ${selectedLink?.default_unit_id !== '' && selectedLink?.default_unit_id !== 'Not Applicable' ? selectedLink?.default_unit_name : selectedLink?.unit_name}`,
            item_rate: selectedLink?.default_unit_rate,
            item_amt: '',
            per_unit: selectedLink?.unit_name,
            item_alternate_unit: selectedLink?.alt_unit_name,
            main_unit_name: selectedLink?.unit_name,
            where_unit_num: selectedLink?.where_unit,
            where_alternate_unit_num: selectedLink?.where_alternate_unit,
            combination_of_units: `${quotient} ${selectedLink?.alt_unit_name}+${remainder} ${selectedLink?.unit_name}`,
            total_nos_box: `${quotient}`,
            total_no_box_unit: selectedLink?.alt_unit_name
        });
    }
    // Otherwise, proceed with default settings
    else {
        updateItemEntry({
            ...commonUpdates
        });
    }
};

export { getValueFromDropdownOfItem };
