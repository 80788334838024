import React from 'react'
import FooterLayout from './FooterLayout'

function VoucherFooter(props) {
    const fields = [
        { id: "1", name: "Quit", shortcutKey: "Q", state:'' },
        { id: "2", name: "", shortcutKey: "", state:'' },
        { id: "3", name: "", shortcutKey: "", },
        { id: "4", name: "Accept", shortcutKey: "A", state:'' },
        { id: "5", name: "", shortcutKey: "", state:'' },
        { id: "6", name: "", shortcutKey: "", state:'' },
        { id: "7", name: "Delete", shortcutKey: "D", },
        { id: "8", name: "Cancel", shortcutKey: "X", },
        { id: "9", name: "", shortcutKey: "", state:'' },
        { id: "10", name: "", shortcutKey: "", state:'' },
    ]
    return (
        <div className='tomasterfooter'>
            <div className='footer'>
                {
                    fields.map((item, index) => {
                        return (
                            <FooterLayout key={item.id} fields={item} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default VoucherFooter
